<template>
	<div>
	<div v-if="structuredGoals.items.length" v-for="lev1item in structuredGoals.items" :key="lev1item.path" id="goal-list" class="goal-list mb-2">
		<template v-if="lev1item.items">
			<h1 :style="headerBgColor(lev1item)"><span class="path">{{ lev1item.path }}</span>{{ lev1item.title }}</h1>
			<ol :id="`group-${lev1item.path.replaceAll('.','-')}`">
				<li v-for="lev2item in lev1item.items" :key="lev2item.path">
					<template v-if="lev2item.items">
						<h2 :style="headerBgColor(lev1item, 0.6)">
							<span class="path">{{ lev2item.path }}</span>
							{{ lev2item.title }}
						</h2>
						<button v-if="action === 'import'" type="button" class="btn-select-all mb-2" @click="selectAllByParentPath(lev2item.path)">
							{{ $t('(De)select all') }}
						</button>
						<ol :id="`group-${lev2item.path.replaceAll('.','-')}`">
							<li v-for="lev3item in lev2item.items" :key="lev3item.path">
								<template v-if="lev3item.items">
									<h3 :style="headerBgColor(lev1item, 0.3)">
										<span class="path">{{ lev3item.path }}</span>
										{{ lev3item.title }}
									</h3>
									<button v-if="action === 'import'" type="button" class="btn-select-all" @click="selectAllByParentPath(lev3item.path)">
										{{ $t('(De)select all') }}
									</button>
									<ol :id="`group-${lev3item.path.replaceAll('.','-')}`">
										<li v-for="lev4item in lev3item.items" :key="lev4item.path">
											<template v-if="lev4item.items">
												<h4>
													<span class="path">{{ lev4item.path }}</span>
													{{ lev4item.title }}
												</h4>
												<button v-if="action === 'import'" type="button" class="btn-select-all" @click="selectAllByParentPath(lev4item.path)">
													{{ $t('(De)select all') }}
												</button>
												<ol :id="`group-${lev4item.path.replaceAll('.','-')}`">
													<li v-for="lev5item in lev4item.items" :key="lev5item.path">
														<GoalListItem :listItem="lev5item" :action="action" :checked="checkedFunction(lev5item.goal)"
															@[propagatedEventChecked]="itemChecked"
															@[propagatedEventUnchecked]="itemUnchecked"
															@[propagatedEventRequestEdit]="editRequested"
															@[propagatedEventRequestRemoval]="removalRequested"
														/>
													</li>
												</ol>
											</template>
											<GoalListItem v-else :listItem="lev4item" :action="action" :checked="checkedFunction(lev4item.goal)"
												@[propagatedEventChecked]="itemChecked"
												@[propagatedEventUnchecked]="itemUnchecked"
												@[propagatedEventRequestEdit]="editRequested"
												@[propagatedEventRequestRemoval]="removalRequested"
											/>
										</li>
									</ol>
								</template>
								<GoalListItem v-else :listItem="lev3item" :action="action" :checked="checkedFunction(lev3item.goal)"
									@[propagatedEventChecked]="itemChecked"
									@[propagatedEventUnchecked]="itemUnchecked"
									@[propagatedEventRequestEdit]="editRequested"
									@[propagatedEventRequestRemoval]="removalRequested"
								/>
							</li>
						</ol>
					</template>
					<GoalListItem v-else :listItem="subitem" :action="action" :checked="checkedFunction(subitem.goal)"
						@[propagatedEventChecked]="itemChecked"
						@[propagatedEventUnchecked]="itemUnchecked"
						@[propagatedEventRequestEdit]="editRequested"
						@[propagatedEventRequestRemoval]="removalRequested"
					/>
				</li>
			</ol>
		</template>
		<GoalListItem v-else :listItem="item" :action="action" :checked="checkedFunction(item.goal)"
			@[propagatedEventChecked]="itemChecked"
			@[propagatedEventUnchecked]="itemUnchecked"
			@[propagatedEventRequestEdit]="editRequested"
			@[propagatedEventRequestRemoval]="removalRequested"
		/>
	</div>
	<div v-else>
		<p class="text-base-content-light pt-4 italic text-center">{{ $tc('Results', 0) }}</p>
	</div>
	</div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { useGoalsStore } from "@/stores/Goals.store";
import GoalListItem from '@/components/GoalListItem.vue';
import { GoalSource } from '@/utils/GoalSource';

export default {

	emits: ['item-checked', 'item-unchecked', 'request-edit', 'request-removal'],

	props: {
		goalSource: {
			type: GoalSource,
			required: true,
		},
		action: {
			type: String,
			default: 'select',
			validates: (value) => ['edit', 'import', 'select'].includes(value),
		},
		checkedFunction: {
			type: Function,
			default: () => false,
		},
	},

	data() {
		return {
		}
	},

	components: {
		GoalListItem,
	},

	computed: {

		...mapStores(useGoalsStore),

		structuredGoals() {
			return this.goalSource.getPopulatedStructure(this.goalsStore.filteredItems);
		},

		// Below computed properties are used to conditionally emit events
		propagatedEventChecked() {
			return (this.action === 'select' || this.action === 'import') ? 'checked' : null;
		},
		propagatedEventUnchecked() {
			return (this.action === 'select' || this.action === 'import') ? 'unchecked' : null;
		},
		propagatedEventRequestEdit() {
			return (this.action === 'edit') ? 'request-edit' : null;
		},
		propagatedEventRequestRemoval() {
			return (this.action === 'edit') ? 'request-removal' : null;
		},
	},

	methods: {

		itemChecked(item) {
			this.$emit('item-checked', item);
		},
		itemUnchecked(item) {
			this.$emit('item-unchecked', item);
		},
		editRequested(item) {
			this.$emit('request-edit', item);
		},
		removalRequested(item) {
			this.$emit('request-removal', item);
		},

		selectAllByParentPath(parentPath) {

			let checkboxElements = Array.from(document.querySelectorAll(`#group-${parentPath.replaceAll('.', '-')} input[type="checkbox"]`));

			const notChecked = (checkbox) => !checkbox.checked;

			if(checkboxElements.some(notChecked)) {
				checkboxElements.forEach(checkbox => {
					if(!checkbox.checked) {
						checkbox.checked = true;
						checkbox.dispatchEvent(new Event('change'));
					}
				});
			} else {
				checkboxElements.forEach(checkbox => {
					if(checkbox.checked) {
						checkbox.checked = false;
						checkbox.dispatchEvent(new Event('change'));
					}
				});
			}

			checkboxElements = null;

		},

		headerBgColor(item, opacity = 1) {
			const rgbColor = item.meta?.color ? this.hexToRgb(item.meta.color) : this.hexToRgb('#86efac')
			return {
				background: 'rgb(' + rgbColor + ',' + opacity + ')'
			}
		},

		hexToRgb(hex) {
			hex = hex.replace(/[^0-9A-F]/gi, '');
    		const bigint = parseInt(hex, 16);
    		const r = (bigint >> 16) & 255;
    		const g = (bigint >> 8) & 255;
    		const b = bigint & 255;

    		return r + "," + g + "," + b;
		}
	}

}
</script>
