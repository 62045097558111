<template>
	<div class="p-10">
		<form @submit.prevent="convertTableData" class="grid grid-cols-5 mb-10">
			<div class="col-span-2 flex flex-col text-sm">
				Table input
				<textarea name="input" rows="12" class="textarea textarea-bordered w-full"></textarea>
			</div>
			<div class="bg-green-200 self-center place-self-center">
				<button type="submit" class="btn btn-sm">&gt;&gt;</button>
			</div>
			<div class="col-span-2 flex flex-col text-sm">
				JSON output
				<textarea name="output" rows="12" class="textarea textarea-bordered w-full"></textarea>
			</div>
			<div class="col-span-5 text-center">
				<input type="reset" value="Reset" class="btn mt-4" />
			</div>
		</form>
	</div>
</template>

<script lang="ts">
export default {

	methods: {
		convertTableData(event) {
			const input = event.target.input.value;

			// split in rows, ignoring newline chars within double quotes
			const rows = input.replace(/("[\s\S]*?")/g, (m, cg) => {
					return cg.replace(/\n/g, "LINE-BREAK-TO-PRESERVE");
				})
				.split('\n')
				.filter(i => Boolean(i.trim()))
				.map(i => i.replace(/LINE-BREAK-TO-PRESERVE/g, '\n'));

			if(!rows.length) {
				return;
			}

			let firstRow = rows[0].split("\t");
			const numCols = firstRow.length;

			if(firstRow[0].length === 0 || firstRow[1].length === 0) {
				console.error("First row must contain at least two columns: code and title");
				return;
			}

			let structure = [];

			// loop over all rows
			for(let i = 0; i < rows.length; i++) {
				const row = rows[i];
				const columns = row.split("\t");

				if(columns.length !== numCols) {
					console.log(row);

					console.error("Row " + i + " has " + columns.length + " columns, but should have " + numCols);
					return;
				}

				// loop over all columns
				let treeDepth = 0;
				for(let j = 0; j < columns.length; j++) {
					if(columns[j].length > 0) {
						break;
					}
					if(j % 2 === 0) {
						treeDepth++;
					}
				}

				const path = columns[treeDepth * 2].trim().replace(/\"/g, "");

				let title = columns[treeDepth * 2 + 1];
				title = title.replace(/\"/g, "");

				// --- GO! specific
				// remove sections starting with "Bv"
				// title = title.replace(/Bv[\s\S]*/, "").trim();
				const examplesTextStartPos = title.search(/Bv/);
				let examplesText = "";
				if(examplesTextStartPos > -1) {
					examplesText = title.substring(examplesTextStartPos);
					title = title.substring(0, examplesTextStartPos).trim();
				}
				title = title.trim();
				// if a goal, only withhold when applicable to pre-school
				if(
					(columns.length >= (treeDepth * 2 + 3) && columns[treeDepth * 2 + 3].length && columns[treeDepth * 2 + 3].trim() === "0")
					&& (columns.length >= (treeDepth * 2 + 5) && columns[treeDepth * 2 + 5].length && columns[treeDepth * 2 + 5].trim() === "0")
					&& (columns.length >= (treeDepth * 2 + 7) && columns[treeDepth * 2 + 7].length && columns[treeDepth * 2 + 7].trim() === "0")
				){
					continue;
				}
				let tags = [];
				const tagResolver = function(targetGroup, level) {
					switch(level) {
						case '1': return targetGroup + ' - Continue';
						case '2': return targetGroup + ' - Introduce';
						case '3': return targetGroup + ' - Reach';
						default: return false;
					}
				}
				if (columns.length >= (treeDepth * 2 + 3) && columns[treeDepth * 2 + 3].length) {
					const tag = tagResolver('YC', columns[treeDepth * 2 + 3].trim());
					if(tag) {
						tags.push(tag);
					}
				}
				if (columns.length >= (treeDepth * 2 + 5) && columns[treeDepth * 2 + 5].length) {
					const tag = tagResolver('EC', columns[treeDepth * 2 + 5].trim());
					if(tag) {
						tags.push(tag);
					}
				}
				if (columns.length >= (treeDepth * 2 + 7) && columns[treeDepth * 2 + 7].length) {
					const tag = tagResolver('1e Gr', columns[treeDepth * 2 + 7].trim());
					if(tag) {
						tags.push(tag);
					}
				}
				let color = null;
				if(treeDepth === 0) {

					switch(path) {
						case '1': color = '#67e8f9'; break;	// Nederlands
						case '3': color = '#bef264'; break; // WO
						case '4': color = '#2dd4bf'; break; // Muzische vorming
						case '5': color = '#0284c7'; break; // Wiskunde
						case '6': color = '#facc15'; break; // LO
						case '7': color = '#a3e635'; break; // Media
					}
				}
				// --- END GO! specific

				const newElement = {
					path: path,
					title: title,
					info: examplesText,
				};
				if(tags.length) {
					if(!newElement['meta']) {
						newElement['meta'] = {};
					}
					newElement['meta']['tags'] = tags;
				}
				if(color) { console.log(color);

					if(!newElement['meta']) {
						newElement['meta'] = {};
					}
					newElement['meta']['color'] = color;
				}
				newElement['items'] = [];

				this.addElementAtDepth(structure, treeDepth, newElement);
			}

			event.target.output.value = JSON.stringify(structure, null, "\t");
		},

		addElementAtDepth(array, depth, elementToAdd) {
			if (depth === 0) {
				array.push(elementToAdd);
			} else {
				this.addElementAtDepth(array[array.length - 1].items, depth - 1, elementToAdd);
			}
		},
	}

}
</script>