<template>
	<div class="text-sm py-1 flex items-start hover:bg-base-100 rounded px-2">

		<label class="swap" v-if="action === 'import' || action === 'select'">
			<input
				type="checkbox"
				name="gcb[]"
				:checked="checked"
				@change="toggleSelect($event, goal)"
			/>
			<div class="swap-off btn btn-xs btn-primary btn-circle mt-1 mr-2">
				<PlusIcon class="w-4 h-4" />
			</div>
			<div class="swap-on btn btn-xs btn-circle btn-outline mt-1 mr-2">
				<CheckIcon class="w-4 h-4" />
			</div>
		</label>

		<div class="w-20 shrink-0 py-1 mt-0.5">{{ goal.fullPath }}</div>
		<div class="grow whitespace-pre-wrap py-1 mt-0.5">
			<!-- <span v-if="!goal.customTitle || action !== 'select'" :class="[goal.customTitle? 'block line-through text-base-content-light font-normal' : '']"
				v-html="goal.highlightedTitle ? goal.highlightedTitle : goal.title">
			</span> -->
			<span
				class="mr-1"
				:class="[
					goal.customTitle ? 'line-through text-base-content-light font-normal' : '',
					goal.customTitle && action === 'select' ? 'inline-block w-16 whitespace-nowrap text-ellipsis overflow-clip' : ''
					]"

			>
				<span v-if="highlightedTitle" :title="goal.title" v-html="highlightedTitle"></span>
				<span v-else :title="goal.title" v-text="goal.title"></span>
			</span>
			<span v-if="goal.customTitle && highlightedCustomTitle" v-html="highlightedCustomTitle"></span>
			<span v-else-if="goal.customTitle && !highlightedCustomTitle" v-text="goal.customTitle"></span>
			<div v-if="goal.info" class="dropdown dropdown-end dropdown-top">
				<div tabindex="0" role="button" class="btn-more-info">{{ $t('More info') }}</div>
				<div tabindex="0" class="dropdown-content card shadow ring-1 ring-inset ring-base-200 bg-base py-2 px-4 w-96 whitespace-pre-wrap" v-text="listItem.info"></div>
			</div>
			<div v-if="withTags && goal.tags?.length" class="flex gap-2 mt-1">
				<Tag v-for="tag in goal.tags" class="tag-xs">{{ tag }}</Tag>
			</div>
		</div>
		<div>
			<template v-if="action === 'import'">
				<span v-if="goal.pendingSync" class="loading loading-spinner loading-xs mr-2 mt-1"></span>
				<span v-if="goal.id" v-text="$t('Imported')" class="badge badge-primary uppercase text-xs mt-1"></span>
			</template>
			<div v-else-if="action=== 'edit' && goal.id" class="flex gap-2">
				<span v-if="goal.pendingSync" class="loading loading-spinner loading-xs mr-2 mt-2"></span>
				<template v-else>
					<button class="btn btn-sm btn-ghost btn-circle bg-base-100" @click="handleGoalEditRequest(goal)">
						<PencilIcon class="w-4 h-4"></PencilIcon>
					</button>
					<button class="btn btn-sm btn-ghost btn-circle bg-base-100" @click="handleGoalRemoveRequest(goal)">
						<TrashIcon class="w-4 h-4"></TrashIcon>
					</button>
				</template>
			</div>
		</div>
	</div>
</template>


<script lang="ts">
import { PencilIcon, TrashIcon, PlusIcon, CheckIcon } from '@heroicons/vue/24/outline';
import Tag from '@/components/ui/Tag.vue';
import {GoalSearchResult} from "@/stores/Goals.store";
import {Goal} from "@/models/Goal.model";

export default {

	emits: ['request-edit', 'request-removal', 'checked', 'unchecked'],

	props: {
		listItem: {
			type: [ Goal, GoalSearchResult ],
			required: true,
		},
		action: {
			type: String,
			default: 'edit',
			validates: (value) => ['edit', 'import', 'select'].includes(value),
		},
		checked: {
			type: Boolean,
			default: () => false,
		},
		withTags: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			goal: null,
			highlightedTitle: null,
			highlightedCustomTitle: null,
		}
	},

	components: {
		PencilIcon, TrashIcon, PlusIcon, CheckIcon,
		Tag,
	},

	beforeMount() {
		if(this.listItem instanceof GoalSearchResult) {
			this.goal = this.listItem.goal;
			this.highlightedTitle = this.listItem.highlightedTitle;
			this.highlightedCustomTitle = this.listItem.highlightedCustomTitle;
		} else {
			this.goal = this.listItem;
		}
	},

	methods: {

		handleGoalEditRequest(goal: Goal) {
			this.$emit('request-edit', goal);
		},

		handleGoalRemoveRequest(goal: Goal) {
			this.$emit('request-removal', goal);
		},

		toggleSelect(event, goal: Goal) {
			if (event.target.checked) {
				this.$emit('checked', goal);
			} else {
				this.$emit('unchecked', goal);
			}
		},

	}
}
</script>
