<template>
	<TheStdLayout :title="$tc('Goal', 2) + ' - ' + $t('Import')">

		<div class="py-2 px-6 border-b border-base-200 flex justify-between items-center sticky bg-base top-0 z-10 print:hidden">

			<router-link :to="{name: 'goals.list'}" class="flex items-center gap-1">
				<button class="btn btn-ghost">
					<ArrowSmallLeftIcon class="w-4 h-4 mr-2" />
					{{ $t('Return') }}
				</button>
			</router-link>
			<div v-if="loaded" class="font-bold pr-2">{{ curriculumName }}</div>

		</div>

		<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

			<template v-if="loaded">
				<GoalFilter
					:initialFilter="goalsStore.filter"
					:goalSource="goalsStore.source"
					:collapsable="true"
					class="mb-8 relative z-10"
				/>

				<GoalList
					v-if="loaded"
					:goalSource="goalsStore.source"
					action="import"
					:checkedFunction="goal => { return goal.id ? true : false; }"
					@itemChecked="importGoal"
					@itemUnchecked="unimportGoal"
				/>
			</template>

			<div v-else class="text-center py-10">
				<span class="loading loading-spinner loading-lg text-primary"></span>
			</div>


		</div>
	</TheStdLayout>
</template>


<script lang="ts">
import { mapStores } from "pinia";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import { useGoalsStore } from "@/stores/Goals.store";
import { Goal } from "@/models/Goal.model";
import GoalFilter from '@/components/GoalFilter.vue';
import GoalList from "@/components/GoalList.vue";
import { PrinterIcon, ArrowSmallLeftIcon } from "@heroicons/vue/24/outline";

import { UnsavedChanges } from "@/utils/UnsavedChanges.util";

// Perhaps not good practice to create such a specific class and, because it's so specific, have it included in this component file,
// but it seemed the easiest way for now to keep it all together while keeping buffering logic separated from component logic.
class UpdateBuffer {

	private importBuffer: Goal[] = [];
	private deleteBuffer: Goal[] = [];
	private bufferTime: number; // ms
	private updateTimeout: any = null;
	private isUpdating: boolean = false;
	private goalsStore: any = null;
	private component: any = null;

	constructor(goalsStore, component, bufferTime = 1000) {
		this.goalsStore = goalsStore;
		this.bufferTime = bufferTime;
		this.component = component;
	}

	addToImportBuffer(item) {

		if (this.deleteBuffer.includes(item)) {
			this.deleteBuffer.splice(this.deleteBuffer.indexOf(item), 1);
			item.pendingSync = false;
			return;
		}
		this.importBuffer.push(item);
		item.pendingSync = true;

		if (!this.isUpdating) {
			this.startUpdateTimer();
		}
	}

	addToDeleteBuffer(item) {

		if (this.importBuffer.includes(item)) {
			this.importBuffer.splice(this.importBuffer.indexOf(item), 1);
			item.pendingSync = false;
			return;
		}
		this.deleteBuffer.push(item);
		item.pendingSync = true;

		if (!this.isUpdating) {
			this.startUpdateTimer();
		}
	}

	startUpdateTimer() {

		UnsavedChanges.markUnsavedChanges();

		if (this.updateTimeout) {
			clearTimeout(this.updateTimeout);
		}

		this.updateTimeout = setTimeout(() => {
			this.handleUpdate();
		}, this.bufferTime);
	}

	async handleUpdate() {
		if (!this.isUpdating && (this.importBuffer.length > 0 || this.deleteBuffer.length > 0)) {
			this.isUpdating = true;

			// Perform the bulk update
			const itemsToImport = [...this.importBuffer];
			this.importBuffer = [];
			const itemsToDelete = [...this.deleteBuffer];
			this.deleteBuffer = [];

			try {
				if (itemsToImport.length > 0) {
					await this.goalsStore.bulkSave(itemsToImport);
				}
			} catch (e) {

				// @Todo: rollback all changes

				this.component.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});
				throw e;

			}

			try {
				if (itemsToDelete.length > 0) {
					await this.goalsStore.bulkDelete(itemsToDelete);
				}
			} catch (e) {

				// @Todo: rollback all changes
				this.component.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});
				throw e;

			}

			this.isUpdating = false;
			UnsavedChanges.markSavedChanges();

			// If new changes occurred during the previous update, trigger another update
			if (this.importBuffer.length > 0 || this.deleteBuffer.length > 0) {
				this.startUpdateTimer();
			}
		}
	}
}



export default {

	components: {
		GoalFilter,
		GoalList,
		PrinterIcon, ArrowSmallLeftIcon,
	},

	data() {
		return {
			loaded: false,
		}
	},

	syncBuffer: null,


	computed: {
		...mapStores(useGoalsStore, useOrganisationsStore),

		curriculumName() {
			return this.goalsStore.source.getName();
		}
	},

	beforeMount() {
		this.syncBuffer = new UpdateBuffer(this.goalsStore, this, 1000);
	},

	async mounted() {
		const curriculum = this.organisationsStore.currentOrganisation.curriculum;
		if (curriculum) {

			await this.goalsStore.load();
			this.goalsStore.filter.clear();
			this.goalsStore.supplementWithGoalsFromSource();
		}

		this.loaded = true;
	},

	beforeUnmount() {
		this.goalsStore.removeNonPersistedItems();
	},

	methods: {

		importGoal(goal:Goal) {
			this.syncBuffer.addToImportBuffer(goal);
		},

		unimportGoal(goal:Goal) {
			this.syncBuffer.addToDeleteBuffer(goal);
		},

	}
}

</script>
