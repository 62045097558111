<template>

<Drawer :title="inUpdateMode? $t('Edit pupil') : $t('Add pupil')" initialWidth="2xl" @requestUnmount="hide">

	<template #default="{ hide }">

		<div v-if="suggestMatchingPupils" class="alert alert-info text-sm">
			{{ $t('#instruction: begin to type to find matches in the pupil directory') }}
		</div>

		<form @submit.prevent="save" class="isolate">

			<div class="pb-16 px-6">

				<div class="bg-base pt-4">
					<p v-if="pupilsStore.errorMessage?.genericError" class="text-red-600 text-xs">
						{{ pupilsStore.errorMessage?.genericError.toString() }}
					</p>

					<div class="grid grid-cols-6 gap-6 row">
						<div class="col-span-6 sm:col-span-3">
							<sms-input type="text" autofocus
								v-model="editableModel.firstName"
								:label="$t('First name')"
								:validationError="pupilsStore.errorMessage?.fields.firstName"
								@input="(event) => refreshAutocomplete('firstName', event.target.value)"
								autocomplete="off" spellcheck="false"
							></sms-input>
						</div>

						<div class="col-span-6 sm:col-span-3">
							<sms-input type="text"
								v-model="editableModel.lastName"
								:label="$t('Name')"
								:validationError="pupilsStore.errorMessage?.fields.lastName"
								@input="(event) => refreshAutocomplete('lastName', event.target.value)"
							   	autocomplete="off" spellcheck="false"
							></sms-input>
						</div>

						<div class="col-span-6 sm:col-span-3">
							<DateSelector
								v-model="dateOfBirth"
								:label="$t('Date of birth')"
								input-css-classes="input-bordered"
								:validationError="pupilsStore.errorMessage?.fields.birthdate"
								:start-date="datePickerStartDate"
								:year-range="[2016, new Date().getFullYear()]"
								:max-date="new Date()"
								:flow="dateOfBirth !== null? ['calendar'] : ['year', 'month', 'calendar']"
								@update:model-value="(event) => refreshAutocomplete('dateOfBirth', event)"
								autocomplete="off" spellcheck="false"
							/>
						</div>
					</div>
				</div>

				<div v-show="autoCompleteSuggestions.length > 0" class="border rounded p-1 mt-4">
					<div class="px-1 py-1 font-bold">Bedoel je soms...</div>
					<ul class="flex flex-col gap-1">
						<li v-for="suggestion in autoCompleteSuggestions" class="py-2 px-2 flex justify-between gap-2 bg-base-100 rounded hover:cursor-pointer hover:bg-accent" @click="selectSuggestion(suggestion)">
							<span class="flex grow items-center gap-3">
								<input type="radio" class="radio radio-sm" />
								{{ suggestion.firstName }}
								{{ suggestion.lastName }}
							</span>
							<span v-show="suggestion.birthdate" class="px-2">*{{ suggestion.birthdate?.toFormat('dd/MM/yyyy') }}</span>
						</li>
					</ul>
				</div>

				<template v-if="showClassroomSelector">
					<div class="border-b border-base-200 mt-8">
						{{ $tc('Classroom', 2) }}
					</div>

					<div v-if="!classrooms.length" class="text-base-content-light py-4 italic text-center">
						{{ $t('No classrooms yet') }}
					</div>

					<div v-else class="flex flex-col gap-1 mt-4">
						<label
							v-for="(classroom, index) in classrooms"
							:for="'cr-' + classroom.id"
							class="flex justify-between w-full px-4 py-1 rounded hover:cursor-pointer hover:bg-base-100 transition-colors duration-200"
						>
							<div class="flex gap-3 items-center py-2">
								<input
									type="checkbox"
									:id="'cr-' + classroom.id"
									class="checkbox checkbox-sm checkbox-primary"
									:checked="editableModel.getClassroomRelation(classroom)?.active"
									@change.prevent="event => editableModel.toggleClassroom(classroom, event.target.checked)"
								/>
								<span>{{ classroom.name }}</span>
							</div>
							<div class="mx-2" v-if="editableModel.getClassroomRelation(classroom)?.active">
								<PupilSymbol :pupil="editableModel" :classroom="classroom" class="w-10"/>
							</div>
						</label>
					</div>
				</template>

				<template v-else>
					<div class="input-label border-b border-base-200 mt-4">
						{{ $t('Symbol') }}
					</div>

					<div class="flex pt-4">
						<div class="mr-8 shrink-0">
							<PupilSymbol :pupil="editableModel" class="w-32" />
						</div>
						<div class="grow">
							<div>
								<button type="button" class="btn" @click="showIconSelector = true">
									{{ $t('Change icon' ) }}...
								</button>
								<!-- <p class="text-error-content" v-if="pupilsStore.errorMessage?.getForField('classrooms', index + 1, 'symbol')">
									{{ $t('Icon is required.') }}
								</p> -->
							</div>
							<!-- Color selector -->
							<div class="flex gap-1 sm:gap-3 mt-4">
								<div
									v-for="color in availableColors"
									@click="editableModel.setSymbolColorForClassroom(currentClassroom, color)"
									:key="color"
									class="cursor-pointer flex items-center justify-center"
									:class="'w-10 h-10 rounded-full bg-' + color + '-200 border-2 border-' + color + '-400 '"
								>
									<CheckIcon v-if="editableModel.getSymbolColorForClassroom(currentClassroom) === color" class="w-6 h-6" />
								</div>
							</div>
						</div>
					</div>
				</template>

			</div>


			<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">

				<Dropdown v-if="inUpdateMode" position="top-start">
					<template #trigger>
						<button v-if="scope === 'organisation'" type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
							<span v-show="deleting" class="loading loading-spinner"></span>
							<TrashIcon class="w-5 h-5" />
						</button>
						<button v-else type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
							<span v-show="deleting" class="loading loading-spinner"></span>
							{{ $t('Remove from classroom')}}
						</button>
					</template>
					<template #content="dialogActions">
						<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @cancel="dialogActions.close" @confirm="confirmRemove(dialogActions)">
							<span class="text-danger">{{ $t('Are you sure?') }}</span>
						</DropdownDialogConfirm>
					</template>
				</Dropdown>

				<div class="flex justify-end gap-1">
					<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
						{{ $t('Cancel') }}
					</button>
					<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
						<span v-show="saving" class="loading loading-spinner"></span>
						{{ $t('Save') }}
					</button>
				</div>

			</div>

		</form>

	</template>

</Drawer>

<IconSelector
	v-if="showIconSelector"
	:isSelectable="isIconSelectable"
	:group="'pupil'"
	:model="editableModel.getClassroomRelation(currentClassroom)"
	:classroom="currentClassroom"
	@icon:selected="editableModel.setIconForClassroom(currentClassroom, $event)"
	@request-close="showIconSelector = false"
/>

</template>


<script lang="ts">
import { mapStores } from 'pinia';

import { useIconsStore } from '@/stores/Icons.store'
import { usePupilsStore } from "@/stores/Pupils.store";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import { usePreferencesStore } from "@/stores/Preferences.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";

import { Pupil } from "@/models/Pupil.model";

import PupilSymbol from "@/components/ui/symbols/PupilSymbol.component.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import { TrashIcon, CheckIcon, MagnifyingGlassIcon, CalendarIcon } from '@heroicons/vue/24/outline';
import {Icon} from "@/models/Icon.model";
import {Symbol} from "@/models/Symbol.model";
import Drawer from '@/components/ui/Drawer.v2.vue';
import DateSelector from "@/components/ui/DateSelector.vue";
import IconSelector from "@/components/IconSelector.vue";
import {DateTime} from "luxon";

export default {
	emits: ['requestClose'],

	props: {
		pupil: {
			type: Pupil,
			required: true,
		},
		scope: {
			type: String,
			default: 'organisation',
		},
	},

	components: {
		Dropdown,
		DropdownDialogConfirm,
    	PupilSymbol,
		TrashIcon, CheckIcon, MagnifyingGlassIcon, CalendarIcon,
		Drawer,
		DateSelector,
		IconSelector,
	},

	data() {
		return {
			canSelectCustomIcons: false,
			saving: false,
			deleting: false,
			availableColors: Pupil.COLORS,
			selectedIconLibraryId: 'default',
			originalModel: null,
			editableModel: null,
			showIconSelector: null,
			autoCompleteTimeout: null,
			autoCompleteSuggestions: [],
		}
	},

  	computed: {
    	...mapStores(useIconsStore, usePupilsStore, usePreferencesStore, useClassroomsStore),

		inUpdateMode() {
			return (this.pupil && this.pupil.id)? true : false;
		},

		datePickerStartDate() {
			const currentYear = new Date().getFullYear();
			return new Date(currentYear - 3, 0, 1);
		},

		dateOfBirth: {
			get(): Date {
				return this.editableModel.birthdate;
			},
			set(value: Date) {
				if (!value) {
					this.editableModel.birthdate = null;
					return;
				}
				this.editableModel.birthdate = DateTime.fromJSDate(value);
			}
		},

		classrooms() {
			return this.classroomsStore.classrooms;
		},

		currentClassroom() {
			return this.classroomsStore.currentClassroom;
		},

		showClassroomSelector() {
			return this.scope === 'organisation';
		},

		suggestMatchingPupils() {
			return !this.inUpdateMode && this.scope === 'classroom';
		},
	},

	beforeMount() {
		if(this.pupil && this.pupil.id) {
			this.originalModel = this.pupil;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.pupil.clone();

		} else {
			this.editableModel = this.pupil;
		}

		this.classroomsStore.load();
	},

  	methods: {

		hide () {
			this.pupilsStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		isIconSelectable(icon: Icon | Symbol) {
			return !this.isIconUsedForOtherPupil(icon);
		},

		isIconUsedForOtherPupil(icon: Icon | Symbol) {
			const pupilIcon = this.editableModel.getIconForClassroom(this.currentClassroom);
			if(pupilIcon && pupilIcon.equals(icon)) {	// used by this pupil
				return false;
			} else {
				return this.pupilsStore.isIconUsed(icon);
			}
		},

		async confirmRemove(dialogActions) {
			dialogActions.close();
			if (this.deleting) {
				return;
			}
			this.deleting = true;

			if(this.scope === 'organisation') {
				try {
					await this.pupilsStore.delete(this.originalModel);
					this.deleting = false;

					this.hide();

					this.$notify({
						text: this.$t('The pupil has been removed'),
						type: "success",
					});

				} catch (e: any) {
					if (!(e instanceof ApiErrors)) {
						throw e;
					}
				}

			} else {

				try {
					await this.editableModel.toggleClassroom(this.classroomsStore.currentClassroom, false);
					await this.pupilsStore.save(this.editableModel);

					this.deleting = false;
					this.hide();

					this.$notify({
						text: this.$t('The pupil has been removed'),
						type: "success",
					});
				} catch (e: any) {

					this.deleting = false;

					if (e instanceof ApiErrors) {
						this.$notify({
							text: this.$t(e.toString()),
							type: "error",
						});
					} else {
						throw e;
					}
				}
			}
		},

		async save() {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.pupilsStore.save(this.editableModel);

				this.saving = false;
				this.hide();

				this.$notify({
					text: this.$t('The pupil has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
			}
    	},

		refreshAutocomplete(updatedField, newValue) {

			if (!this.suggestMatchingPupils) {
				return;
			}

			// initialize
			let fn = this.editableModel.firstName;
			let ln = this.editableModel.lastName;
			let dob = this.editableModel.birthdate;

			// editableModel value for the changed field may not be updated yet, so take the changed value straight from the event
			switch(updatedField) {
				case 'firstName':
					fn = newValue;
					break;
				case 'lastName':
					ln = newValue;
					break;
				case 'dateOfBirth':
					if(newValue instanceof Date) {
						dob = DateTime.fromJSDate(newValue);
					} else {
						dob = null;
					}
					break;
			}

			// only start searching if at least one field has a useful value
			if( (!fn || fn.length < 2) &&
				(!ln || ln.length < 2) &&
				(dob === null)
			) {
				this.autoCompleteSuggestions = [];
				return;
			}

			if (this.autoCompleteTimeout) {
				clearTimeout(this.autoCompleteTimeout);
			}

			this.autoCompleteTimeout = setTimeout(async () => {

				this.autoCompleteSuggestions = (await this.pupilsStore.findByNameAndDateOfBirth(
					fn,
					ln,
					dob? dob.toSQLDate() : dob
				)).filter((pupil: Pupil) => {
					return !pupil.belongsToClassroom(this.currentClassroom);
				});

				// limit the number of suggestions
				this.autoCompleteSuggestions = this.autoCompleteSuggestions.slice(0, 6);

			}, 400);
		},

		async selectSuggestion(suggestion) {

			if (this.inUpdateMode || this.scope === 'organisation') {
				return;
			}

			const classroom = this.classroomsStore.currentClassroom;

			this.editableModel = suggestion.clone();
			await this.editableModel.toggleClassroom(classroom, true);

			this.autoCompleteSuggestions = [];
		}
  	},
}
</script>
