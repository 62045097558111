<template>

<Drawer :title="panelTitle" initialWidth="3xl" :showDefaultCloseButton="false" @requestUnmount="close">

	<template #default="{ hide }">

		<div v-if="!loaded" class="text-center py-20">
			<span class="loading loading-spinner loading-lg text-primary"></span>
		</div>

		<div v-else-if="!editableModel" class="pt-4 pb-24">

			<div class="sticky top-4 bg-white/50 backdrop-blur-sm z-10">
				<div class="flex flex-col items-end px-4 gap-4">
					<button type="button" class="btn btn-primary rounded-full" @click="createNewLessonPlan">
						<PlusIcon class="w-5 h-5 mr-1" />
						<span>{{ $t('New') }}</span>
					</button>
				</div>

				<div class="mx-4">
					<LessonPlanFilter :initialFilter="filter" :resultCounter="null" :collapsable="false" @change="applyFilter" class="my-6 relative z-10"></LessonPlanFilter>

					<div class="text-sm pl-2 mb-2">{{ $tc('Results', lessonPlansStore.items.length) }}</div>
					<hr>
				</div>
			</div>
			<div class="mx-4">
				<ul class="flex flex-col gap-1 mt-2 relative z-0">
					<li v-for="lessonPlan in lessonPlansStore.items"  @click="selectLessonPlan(lessonPlan)" class="group relative flex gap-2 rounded py-2 pl-10 pr-10 ring-accent hover:cursor-pointer hover:ring-1 hover:bg-base-100">
						<DocumentTextIcon class="w-5 h-5 absolute left-2" />
						<span>{{ lessonPlan.title }}</span>
						<Tag v-for="tag in lessonPlan.themeTags" :key="tag.id" :removeable="false" class="tag-theme tag-xs">
							{{ tag.name }}
						</Tag>
						<Tag v-for="tag in lessonPlan.activityTags" :key="tag.id" :removeable="false" class="tag-activity tag-xs">
							{{ tag.name }}
						</Tag>
						<ChevronRightIcon class="w-5 h-5 absolute right-2 top-2 invisible group-hover:visible" />
					</li>
				</ul>

			</div>

			<div class="absolute bottom-0 w-full bg-white/50 backdrop-blur-sm z-10 p-4 flex mt-6 border-t border-base-200 justify-end">
				<button type="button" class="btn" @click="close">{{ $t("Cancel") }}</button>
			</div>

		</div>

		<form v-else class="grid grid-cols-1 gap-6 isolate">

			<div class="pb-16 px-6">

				<div v-if="editableModel.isSavedInLib" class="sticky top-0 z-10 bg-base pt-6">
					<div class="alert alert-warning py-2 text-sm mb-4 flex justify-between">
						<template v-if="isMyPlan">
							<template v-if="readOnly">

								{{ $t('This lesson plan is stored in your library') }}

								<button v-if="editableModel.isLatestVersion()" type="button" class="px-3 py-2 rounded-lg bg-warning-content bg-opacity-10 hover:bg-opacity-20 btn-effect" @click="tryUnlock">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-6 h-6 fill-current">
										<path d="M248 384C248 397.3 237.3 408 224 408C210.7 408 200 397.3 200 384V320C200 306.7 210.7 296 224 296C237.3 296 248 306.7 248 320V384zM224 0C294.7 0 352 57.31 352 128V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H96V128C96 57.31 153.3 0 224 0zM224 48C179.8 48 144 83.82 144 128V192H304V128C304 83.82 268.2 48 224 48zM64 240C55.16 240 48 247.2 48 256V448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V256C400 247.2 392.8 240 384 240H64z"/>
									</svg>
								</button>
							</template>
							<template v-else>
								<div>
									{{ $t("Warning: Changes to this lesson plan will also be saved to your library") }}
								</div>
								<div class="flex gap-2">
									<button type="button" class="px-3 py-2 rounded-lg border border-warning-content border-opacity-50 hover:bg-warning-content hover:bg-opacity-10 whitespace-nowrap btn-effect" @click="revert">
										{{ $t('Revert') }}
									</button>

									<!-- @TODO handle saving state -->
									<!-- <button type="button" class="flex px-3 py-2 rounded-lg border border-warning-content border-opacity-50 hover:bg-warning-content hover:bg-opacity-10 whitespace-nowrap btn-effect" @click="saveLessonPlanInLibrary">
										<FolderArrowDownIcon class="w-5 h-5 mr-2" />{{ $t('Save') }}
									</button> -->
								</div>
							</template>
						</template>

						<div v-else-if="editableModel.owner">
							{{ $t('This lesson plan belongs to {name}', { name: editableModel.owner.getNameIdentifier() }) }}
						</div>

						<div v-else>
							{{ $t('This lesson plan cannot be edited') }}
						</div>

					</div>

					<div class="alert alert-warning py-2 text-sm mb-4 flex justify-between" v-if="!editableModel.isLatestVersion()">
						{{ $t('This is an old version of the lesson plan. You can find the latest version in your library.') }}
					</div>
				</div>

				<LessonPlanForm
					:lessonPlan="editableModel"
					:showOptionGuided="true"
					:readOnly="readOnly"
					:classroomContext="true"
					:suggestedGoals="suggestedGoals"
					class="my-8">
				</LessonPlanForm>

			</div>

			<div class="absolute bottom-0 w-full bg-white/50 backdrop-blur-sm z-10 p-4 flex mt-6 border-t border-base-200 justify-end">
				<div v-show="!editableModel.isSavedInLib && editableModel.isValid() && isMyPlan" class="flex justify-end">
					<p class="text-sm mr-2 text-base-content-light text-right max-w-xs">
						{{ $t('Optional: Add this lesson plan to your own library to reuse later') }}
					</p>
					<button type="button" class="btn btn-neutral relative" @click="saveLessonPlanInLibrary">
						<span v-show="false" class="loading loading-spinner"></span>
						<FolderArrowDownIcon class="w-7 h-7" />
					</button>

				</div>

				<div class="ml-8 flex">
					<ActionConfirm position="top-end" class="w-full md:w-[30rem] text-danger font-bold" v-slot="confirmationDialog">
						<button type="button" class="btn btn-primary" @click="doneEditing(confirmationDialog)">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Done') }}
						</button>
					</ActionConfirm>
				</div>
			</div>
		</form>

	</template>

</Drawer>

</template>



<script lang="ts">
import { FolderArrowDownIcon, PlusIcon, ChevronRightIcon, XMarkIcon, TrashIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
import { LessonItem } from "@/models/LessonItem.model";
import { LessonPlan } from "@/models/LessonPlan.model";
import LessonPlanForm from "@/components/LessonPlanForm.vue";
import LessonPlanFilter from "@/components/LessonPlanFilter.vue";
import { mapStores } from "pinia";
import { LessonPlanFilterDef, useLessonPlansStore } from "@/stores/LessonPlans.store";
import { useTagsStore } from "@/stores/Tags.store";
import Tag from "@/components/ui/Tag.vue";
import { Lesson } from "@/models/Lesson.model";
import ActionConfirm from './ui/ActionConfirm.vue';
import { useUsersStore } from "@/stores/Users.store";
import { FilterParamValue } from '@/utils/FilterDefs.util';
import Drawer from '@/components/ui/Drawer.v2.vue';
import {useThemesStore} from "@/stores/Themes.store";
import {Goal} from "@/models/Goal.model";

export default {

	emits: ['requestClose'],

	props: {
		lessonItem: {
			type: LessonItem,
			required: true,
		},

		lesson: {
			type: Lesson,
			required: true,
		}
	},

	components: {
		Tag,
		FolderArrowDownIcon, PlusIcon, ChevronRightIcon, TrashIcon, XMarkIcon, DocumentTextIcon,
		LessonPlanForm,
		LessonPlanFilter,
		ActionConfirm,
		Drawer,
	},

	computed: {
		...mapStores(
			useLessonPlansStore,
			useUsersStore,
			useTagsStore,
			useThemesStore
		),

		canClose() {

			if (!this.editableModel) {
				return true;
			}

			if (this.editableModel.isSavedInLib) {
				return true;
			}

			if (this.readOnly) {
				return true;
			}

			return this.editableModel.isValid();
		},

		isBoardTypeActivities() {
			return this.lesson.boardType === Lesson.BOARD_TYPE_ACTIVITIES;
		},

		// canClear() {
		// 	return this.canClose || !this.lessonItem?.lessonPlan?.isValid();
		// }

		isMyPlan() {
			return this.editableModel?.owner?.id == this.usersStore.me?.id;
		},

		panelTitle() {
			let title = this.$t('Lesson plan');
			if( this.lessonItem.activity) {
				title += ' - ' + this.lessonItem.activity.name;
			}
			return title;
		},
	},

	data() {
		return {
			readOnly: false,
			filter: null,
			originalLessonPlan: null,
			titleError: false,
			saving: false,
			editableModel: null,
			loaded: false,
			suggestedGoals: [],
		}
	},

	beforeMount() {
		this.filter = new LessonPlanFilterDef();

		this.editableModel = this.lessonItem.lessonPlan?.clone();
		this.readOnly = this.editableModel?.isSavedInLib;
	},

	async mounted() {
		// No editable model, so library will be shown first. Let's see if we can prefilter the lesson plans based on the (choice) activity name
		if(!this.editableModel && this.lessonItem.activity) {
			await this.tagsStore.load();

			let matchingTag = this.tagsStore.findByName(this.lessonItem.activity.name);
			if (matchingTag) {
				this.filter.addParamValue('activityLabels', new FilterParamValue(matchingTag.id, matchingTag.name));
			}
		}
		await this.lessonPlansStore.load(null, this.filter);

		let themes = this.themesStore.getActiveAt(this.lesson.start);
		themes.forEach(theme => {
			this.suggestedGoals.push(
				...theme.goals.filter(
					(goal: Goal) => this.suggestedGoals.find((g: Goal) => g.id === goal.id) === undefined
				)
			);
		});

		this.loaded = true;
	},

	methods: {

		async createNewLessonPlan() {
			this.editableModel = await this.lessonPlansStore.new(this.lessonItem, this.lesson);
		},

		close() {
			if (this.canClose) {
				this.$emit('requestClose');
			}
		},

		async doneEditing(confirmationDialog) {

			// Read only? Nothing to do.
			if (this.readOnly) {
				this.lessonItem.lessonPlan = this.editableModel;
				this.$emit('requestClose');
				return;
			}

			if (!this.editableModel.isValid()) {
				confirmationDialog.setMessage( this.$t('Without the required title, we cannot save your lesson plan. Close without saving?') );
				const confirmed = await confirmationDialog.show();
				if(!confirmed) {
					return;
				}
			}

			if (this.editableModel.isSavedInLib) {
				if (!this.editableModel.isValid()) {
					this.revert();
				} else {
					await this.saveLessonPlanInLibrary();
				}
			}

			this.lessonItem.lessonPlan = this.editableModel;
			this.$emit('requestClose');
		},

		// clear() {
		// 	this.lessonItem.lessonPlan = null;
		// 	this.readOnly = false;
		// },

		selectLessonPlan(lessonPlan: LessonPlan) {
			this.editableModel = lessonPlan;
			this.readOnly = true;
		},

		tryUnlock() {
			if (!this.isMyPlan) {
				return;
			}

			this.readOnly = false;
			this.originalLessonPlan = this.editableModel.clone();
		},

		revert() {
			this.readOnly = true;
			if (this.originalLessonPlan) {
				this.editableModel.setFromModel(this.originalLessonPlan);
			}
		},

		applyFilter(filter: LessonPlanFilterDef) {
			this.filter = filter;
			this.lessonPlansStore.load(null, this.filter);
		},

		async saveLessonPlanInLibrary() {

			if (
				!this.editableModel.isValid() ||
				!this.isMyPlan
			) {
				return;
			}

			if (this.originalLessonPlan && this.originalLessonPlan.equals(this.editableModel)) {
				// No changes, nothing to save
				this.readOnly = true;
				return;
			}

			this.saving = true;

			try {
				await this.lessonPlansStore.save(this.editableModel);
				this.readOnly = true;
			} catch (e) {
				this.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});
				throw e;
			} finally {
				this.saving = false;
			}
		},
	}
}
</script>
