import { Goal } from "@/models/Goal.model";
import { GoalSearchResult } from "@/stores/Goals.store";

interface GoalSourceItemContainer {
	items: GoalSourceContentItem[];
}

interface GoalSourceContent extends GoalSourceItemContainer {
	copyright: string;
	name: string;
	version: string;
}

interface GoalSourceContentItem extends GoalSourceItemContainer {
	path: string;
	title: string;
	info: string;
	items: GoalSourceContentItem[];
}

interface PopulatedGoalStructureContent {
	items: PopulatedGoalStructureContentItem[];
}

interface PopulatedGoalStructureContentItem extends Goal {
	path: string;
	title: string;
	info: string;
	items: (GoalSearchResult | PopulatedGoalStructureContentItem)[];
}

export class GoalSource {

	private basePath: string = '/goal-sources/';
	private sourceId: string = null;

	/** @warning This is a private property, do not use it directly. Instead, use getEmptyStructure to get a clean copy */
	private emptyStructureJson: string = null;

	public content: GoalSourceContent | null = null;

	private loadPromise: Promise<void> | null = null;


	constructor() {}

	async load(sourceId: string) {

		if (sourceId !== this.sourceId) {
			this.sourceId = sourceId;
			this.loadPromise = null;
		}

		if (this.loadPromise !== null) {
			return this.loadPromise;
		}

		this.loadPromise = new Promise<void>(async (resolve, reject) => {

			let url = this.basePath + sourceId + '.json';

			try {
				const response = await fetch(url)
				const content = await response.json();

				if (this.sourceId !== sourceId) {
					reject();
					return;
				}

				this.content = content;
				this.emptyStructureJson = JSON.stringify(this.buildEmptyStructure());

				resolve();
			} catch (e) {
				console.error(e);
				reject();
			}

		});
		return this.loadPromise;

	}

	getName() {
		return this.content?.name;
	}

	getVersion() {
		return this.content?.version;
	}

	buildEmptyStructure(): PopulatedGoalStructureContent {

		// if(!this.content.items) {
		// 	return {};
		// }
		function traverse(node: GoalSourceItemContainer) {

			// If the node has children, create a new object and recursively traverse
			if (node.items && node.items.length > 0) {
				return {
					...node,
					items: node.items.map(traverse).filter(Boolean) // Remove nodes without children
				};
			}

			// If the node has no children, return undefined to filter it out
			return undefined;
		}

		// Start the traversal from the root
		return traverse(this.content);
	}

	getEmptyStructure(): PopulatedGoalStructureContent | undefined {
		if (!this.emptyStructureJson) {
			return {
				items: []
			};
		}

		// Ugly, but performant.
		return JSON.parse(this.emptyStructureJson);
	}

	/**
	 * Generate a map of all nodes in the structured goal data, indexed by their path
	 */
	calculateNodePathMap(structuredGoalData: PopulatedGoalStructureContent): Map<string, PopulatedGoalStructureContentItem> {

		const map = new Map<string, PopulatedGoalStructureContentItem>();

		const traverse = (node: PopulatedGoalStructureContentItem) => {
			if (node.path) {
				map.set(node.path, node);
			}

			if (node.items && node.items.length > 0) {
				node.items.forEach(traverse);
			}
		}

		structuredGoalData.items.forEach(traverse);
		return map;

	}

	getGoals() {
		return this.extractGoals();
	}

	getPopulatedStructure(searchResults: GoalSearchResult[]): PopulatedGoalStructureContent {

		// Is loaded?
		if (!this.content) {
			return {
				items: []
			} as PopulatedGoalStructureContent;
		}

		if (!searchResults.length) {
			// return only first node of the structure with general source info (and empty items array)
			let structure: { [key: string]: any } = {};
			for (const [key, value] of Object.entries(this.content)) {
				structure[key] = value;
			}
			structure.items = [];
			return structure as PopulatedGoalStructureContent;
		}

		let structuredGoalData = this.getEmptyStructure();
		let nodeMap = this.calculateNodePathMap(structuredGoalData);

		searchResults.forEach((searchResult: GoalSearchResult) => {
			const parentPath = searchResult.goal.sourceParentPath;

			const parent = nodeMap.get(parentPath);
			if (parent) {
				parent.items.push(searchResult);
			}
		});

		const cleanedUpStructure = this.removeNodesWithoutChildren([ structuredGoalData ]);
		return cleanedUpStructure[0];

	}

	private extractGoals(asGoalObjects = true) {

		const result = [];

		const traverse = (node) => {

			if (node.items && node.items.length > 0) {
				node.items.forEach(traverse);
			} else {
				if(asGoalObjects) {
					node = Goal.mapFromStaticGoalSourceData(node, this.sourceId);
				}
				result.push(node);
			}
		}

		if (!this.content) {
			return [];
		}

		traverse(this.content);
		return result;
	}

	private withoutGoalNodes(structuredData) {
		return structuredData.filter(obj => {
			const keep = obj.items && obj.items.length > 0;	// TODO might not be 100% reliable
			if(keep) {
				obj.items = this.withoutGoalNodes(obj.items);
			}
			return keep;
		});
	}

	private removeNodesWithoutChildren(data) {

		return data.filter(item => {
			if (!item.items) {
				// Keep goals
				return true;
			}
			else if (item.items.length > 0) {
				// Recursively remove nodes without children in the children array
				item.items = this.removeNodesWithoutChildren(item.items);
				return item.items.length > 0;
				// return true;
			}
			// Exclude nodes without children from the result
			return false;
		});
	}

}
