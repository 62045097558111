<template>
	<GoalSelectorZill
		v-if="curriculum.substring(0,4).toLowerCase() === 'zill'"
		:context="context"
		@requestUnmount="$emit('requestUnmount')"
		:selectedGoals="selectedGoals"
		:suggestedGoals="suggestedGoals"
		@goalSelected="addGoal"
		@goalUnselected="removeGoal"
	/>

	<GoalSelectorGeneric
		v-else
		@requestUnmount="$emit('requestUnmount')"
		:selectedGoals="selectedGoals"
		:suggestedGoals="suggestedGoals"
		@goalSelected="addGoal"
		@goalUnselected="removeGoal"
	/>
</template>


<script lang="ts">
import GoalSelectorGeneric from '@/components/GoalSelectorGeneric.vue';
import GoalSelectorZill from '@/components/GoalSelectorZill.vue';

export default {

	emits: ['requestUnmount', 'goalSelected', 'goalUnselected'],

	props: {
		curriculum: {
			type: String,
			required: true,
		},

		selectedGoals: {
			type: Array,
			default: () => [],
		},

		suggestedGoals: {
			type: Array,
			default: () => [],
		},

		context: {
			type: String,
		},
	},

	components: {
		GoalSelectorGeneric,
		GoalSelectorZill,
	},

	methods: {
		addGoal(goal) {
			this.$emit('goalSelected', goal);
		},

		removeGoal(goal) {
			this.$emit('goalUnselected', goal);
		},
	}

}
</script>